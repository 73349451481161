.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.homeHeader {
  display: flex;
  width: max-content;
}
.code {
  font-weight: 700;
}
.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, auto));
  max-width: 100%;
  border: 2px solid var(--text-color);
}
.card {
  padding: 1em 0.8em 1.2em 0.8em;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
  flex: 1;
  border: 1px solid var(--text-xolor);
}
.card span {
  display: inline-block;
  transition: transform 200ms;
}
.card h2 {
  font-weight: 600;
  margin-bottom: 0.7em 0.8em;
}
.card p {
  margin: 0;
  font-size: 0.9em;
  line-height: 1.4;
  max-width: 30ch;
}
.center {
  display: flex;
  padding: 2em 0.8em 0;
  margin-inline: 0.5em;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  gap: 2em 0.8em;
  font-size: clamp(1.2em, 2.4vw, 1.4em);
  align-items: center;
  word-wrap: break-word;
}
.center:has(p) {
  display: flex;
  flex-wrap: wrap;
}
.gridOne p,
.gridTwo p {
  grid-area: 'One';
  word-break: break-word;
  padding-bottom: 1rem;
}
.gridTwo p span {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em 0.8em;
}
.gridFour {
  grid-area: 'Three';
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
}
.gridFour p {
  word-break: break-word;
  display: flex;
  flex-wrap: wrap;
  min-width: fit-content;
  align-items: center;
}
.gruppOne {
  flex-wrap: wrap;
  word-break: break-word;
  padding-inline: 1rem;
  width: 270px;
  display: flex;
  justify-content: center;
}
.gruppTwo {
  display: flex;
  flex-wrap: wrap;
  max-width: 320px;
}
.gruppThree:has(li) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-inline: 1em;
  padding-bottom: 0.5em;
}
.gruppOne li {
  margin-bottom: 0.5em;
}
.gruppTwo li {
  margin-bottom: 0.5em;
}
.gruppThree li {
  margin-bottom: 0.5em;
}
.fieldset ul li {
  list-style: none;
  padding-block: 0.2em;
}
.fieldset li {
  font-size: 0.8em;
}
.fieldset {
  display: flex;
  justify-content: space-around;
  align-content: space-between;
  align-items: stretch;
  flex-direction: column;
  padding-inline: 0.6em;
}
.fieldsetList li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.fieldsetList .span {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.fieldsetList p {
  display: flex;
  align-content: flex-end;
  justify-content: center;
  font-size: 0.8em;
  color: var(--text-color);
}
.swishSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.swishSection h3 {
  color: var(--text-color);
  font-weight: 600;
}
.website {
  letter-spacing: 0.6em 0.8em;
}
.tel {
  align-self: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-left: 1em 0.8em;
}
.swishDesc {
  color: var(--text-color);
  font-size: 0.8em;
}
.swishContianer {
  display: flex;
}
.logoSwish {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--text-color);
  border-radius: 10px;
}
.logo {
  position: relative;
  transform: rotate(-15deg);
}
.span {
  font-size: 12px;
}
.imageCard {
  height: 350px;
  width: 200px;
  position: relative;
  padding: 0 2px 0 2px;
}
.imageCard img {
  padding: 0 2px 0 2px;
}
.menuTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.grill span {
  width: 300px;
  display: flex;
  font-size: 8px;
  align-content: flex-end;
  justify-content: flex-end;
  padding-block: 1em 0.8em;
}

.sectionSize {
  display: flex;
  justify-content: space-evenly;
}
.mexicanHead {
  padding-top: 1.5rem;
}
.italiensk {
  align-content: center;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}
.italiensk p {
  font-size: 0.8em;
  opacity: 0.8;
  padding: 1rem;
}
.cardOne {
  padding: 1em 0.8em 1.2em 0.8em;
  border-radius: var(--border-radius);
  background: rgba(var(--card-rgb), 0);
  border: 1px solid rgba(var(--card-border-rgb), 0);
  transition: background 200ms, border 200ms;
  border: solid 1px var(--text-color);
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}
.cardTitle {
  padding: 0 1.6em 1.6em 1em;
  color: var(--text-color);
  font-size: 1.2em;
}
.text {
  padding: 0 1.6em 1.6em 1.6em;
  color: var(--text-color);
}
.map {
  width: 100%;
}

@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }
  .card:hover span {
    transform: translateX(4px);
  }
}
@media (prefers-reduced-motion) {
  .card:hover span {
    transform: none;
  }
}

@media (max-width: 700px) {
  .content {
    padding: 4em 0.8em;
  }
  .headerTop h1 {
    color: var(--headColor);
    margin-top: 20vh;
    font-size: clamp(2.5em, 2.5vw, 5em);
  }
  .grid {
    grid-template-columns: 1fr;
    margin-bottom: 120px;
    text-align: center;
  }
  .card {
    padding: 1em 0.8em 2.5em 0.8em;
  }
  .card h2 {
    margin-bottom: 0.5em 0.8em;
  }
  .para {
    padding: 8px;
  }
  .main {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media (min-width: 701px) and (max-width: 1120px) {
  .grid {
    grid-template-columns: repeat(2, 50%);
  }
  .gruppOne {
    min-width: max-content;
  }
  .sectionLists {
    display: flex;
    flex-direction: column;
    min-width: 550px;
  }
  .main {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
